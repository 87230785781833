<template>
    <section>
        <loading
            v-if="isLoading"
            key="stage-loading"
        />

        <study
            v-if="stage.study"
            type-test="document"
            key="stage-study"
        />

        <start
            v-if="stage.start"
            key="stage-start"
            @onStart="onStartTesting"
        />

        <question
            v-if="stage.question"
            key="stage-question"
            :on-complete="onTestingCompleted"
            :on-submit="onTestingSubmit"
        />

        <repeat
            v-if="stage.repeat"
            key="stage-repeat"
        />

        <attempts
            v-if="stage.attempts"
            key="stage-attempts"
        />

        <timer
            v-if="stage.timer"
            :info="stage.info"
            :on-timer-stop="loadTemplate"
            key="stage-timer"
        />

        <wait
            v-if="stage.wait"
            key="stage-wait"
        />

        <end
            v-if="stage.end"
            key="stage-end"
        />
    </section>
</template>

<script>
    import session from '@/api/session';
    export default {
        name: 'TestDocument',
        components: {
            Loading: () => import('@/components/test/testing/stages/Loading'),
            Start: () => import('@/components/test/testing/stages/Start'),
            Wait: () => import('@/components/test/testing/stages/Wait'),
            End: () => import('@/components/test/testing/stages/End'),
            Timer: () => import('@/components/test/testing/stages/Timer'),
            Attempts: () => import('@/components/test/testing/stages/Attempts'),
            Repeat: () => import('@/components/test/testing/stages/Repeat'),
            Question: () => import('@/components/test/testing/stages/Question'),
            Study: () => import('@/components/test/testing/stages/Study'),
        },

        data() {
            return {
                stage: {
                    start: true,
                    question: false
                },
                answers: {},
                isLoading: false
            };
        },

        methods: {
            async loadTemplate() {
                this.isLoading = true;

                try {
                    const request = await session.get(`/api/v1/document/${this.$route.query.id}/load_template/`);
                    this.stage = request.data;
                } catch (error) {
                    console.error(error);

                    this.$swal({
                        title: 'Ошибка!',
                        text: 'При обработке запроса произошла ошибка на сервере',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1400
                    });
                }

                this.isLoading = false;
            },

            async onStartTesting() {
                this.stage.start = false;
                this.stage.question = true;
            },

            async onTestingSubmit(answersList) {
                try {
                    this.stage.question = false;
                    this.isLoading = true;

                    const request = await session.post(`api/v1/document/${this.$route.query.id}/answer_question/`, answersList);
                    const { data } = request;

                    this.stage = Object.assign(this.stage, data);

                    if (data.is_wait) {
                        this.stage.wait = true;
                    } else if (data.is_passed) {
                        this.stage.end = true;
                    } else {
                        this.stage.repeat = true;
                    }

                    this.isLoading = false;
                } catch (e) {
                    console.error(e);
                }
            },

            onTestingCompleted() {
                this.stage.question = false;
                this.stage.end = true;
            }
        },

        async mounted() {
            await this.loadTemplate();
        }
    };
</script>
